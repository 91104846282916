import { noop } from 'lodash';
import React from 'react';

import { FormField, Layout, useToggle } from '~/eds';
import { Filter } from '~/evifields';
import { QueryFilters } from '~/features/advanced-search/query-filters';
import { SearchQuery } from '~/features/advanced-search/types';
import { SearchFilter } from '~/types';

interface Props {
  query: SearchQuery;
  onQueryChange: (updatedQuery: SearchQuery) => void;
  searchFilters: SearchFilter[];
  title: string;
  defaultFilters?: Array<string>;
  description?: string;
  descriptionLink?: {
    linkText: string;
    pathname: string;
    additionalText?: string;
    tooltip?: string;
  };
  editMode?: boolean;
  isLoading?: boolean;
  optional?: boolean;
  required?: boolean;
  readOnly?: boolean;
  onSaveFilters?: () => void;
  onCancel?: () => void;
}

export const QueryFiltersFormField = ({
  defaultFilters,
  description,
  descriptionLink,
  editMode,
  isLoading,
  query,
  optional = true,
  required = false,
  readOnly = false,
  searchFilters,
  title,
  onQueryChange,
  onSaveFilters,
  onCancel,
}: Props) => {
  const [
    isEditing,
    _toggleIsEditing,
    enableIsEditing,
    disableIsEditing,
  ] = useToggle();

  return (
    <FormField<Filter[], true>
      actions={
        editMode
          ? !isEditing
            ? [
                {
                  icon: 'edit',
                  text: 'Edit Filters',
                  level: 'action',
                  onClick: enableIsEditing,
                },
              ]
            : [
                {
                  icon: 'x',
                  text: 'Cancel',
                  level: 'action',
                  onClick: () => {
                    onCancel?.();
                    disableIsEditing();
                  },
                },
                {
                  icon: 'check',
                  text: 'Save Changes',
                  level: 'action',
                  onClick: () => {
                    onSaveFilters?.();
                    disableIsEditing();
                  },
                },
              ]
          : []
      }
      required={required}
      description={description}
      descriptionLink={descriptionLink}
      input={null}
      label={title}
      name="search-filters-preview"
      onChange={noop}
      optional={optional}
      value={null}
    >
      <Layout pt={2}>
        <QueryFilters
          defaultFilters={defaultFilters}
          isLoading={isLoading}
          onQueryChange={onQueryChange}
          query={query}
          readOnly={readOnly || (editMode && !isEditing)}
          searchFilters={searchFilters}
        />
      </Layout>
    </FormField>
  );
};
