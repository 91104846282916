import { Box, Layout, Progress, Text } from '~/eds';
import { StatusType } from '~/eds/src/types';

interface Props {
  consumedQuota: number;
  max: number;
  isLoading?: boolean;
  mode?: 'default' | 'compact';
  status?: StatusType;
  onClick?: () => void;
}
export const ModelQuotaUsage = ({
  isLoading,
  mode = 'default',
  consumedQuota,
  max,
  status,
  onClick,
}: Props) => {
  const isDefault = mode === 'default';

  const handleClick = () => onClick?.();

  const isOverMax = consumedQuota > max;

  const exceedsMaxColor = isOverMax ? 'status.danger' : 'text.quiet';

  return (
    <Layout
      borderRadius="m"
      direction="column"
      spacing={2}
      py={2}
      px={4}
      ml={-4}
      onClick={handleClick}
    >
      <Box position="absolute" top="50%" left="50%" zIndex={1}>
        <Text variant="body-medium">Total Models:</Text>
      </Box>
      <Layout w={isDefault ? undefined : '180px'}>
        <Progress
          isLoading={isLoading}
          name="model-usage"
          max={max}
          status={status}
          statsLabel={{
            position: 'top',
            variant: 'tiny',
            textAlign: 'right',
            color: exceedsMaxColor,
          }}
          value={consumedQuota}
        />
      </Layout>
      {isOverMax && (
        <Text variant="tiny" color="status.danger">
          {isDefault
            ? 'Over the purchased number of models.'
            : `${consumedQuota - max} over`}
        </Text>
      )}
    </Layout>
  );
};
