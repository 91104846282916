import { capitalize } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CardSelect,
  Checkbox,
  DEFAULT_POLL_INTERVAL_MS,
  Divider,
  Layout,
  Modal,
  pluralize,
  Section,
  Text,
  useModal,
  useToast,
} from '~/eds';
import { api, slices } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';

import { useGetModelsSummary } from '../../billing/hooks';
import { PUBLISH_ELEMENT_ID } from '../../constants';
import { ModelPreview } from '../ModelLibrary/ModelPreview';
import { LibraryModelVersion } from '../types';
import { CategoryScope } from './CategoryScope';
import { GlobalScope } from './GlobalScope';
import { ModelPublishStates } from './ModelPublishStates';
import {
  updateAllModelsCustomScope,
  updateAllModelsGlobalScope,
} from './utils';

type Callback = () => void;

type ScopeType = 'global' | 'custom';

interface Props {
  onBack: Callback;
  onCancel: Callback;
}

export const ModelAddScope = ({ onBack, onCancel }: Props) => {
  const dispatch = useDispatch();
  const { navigate } = useRouting();
  const { toast } = useToast();
  const [scopeType, setScopeType] = useState<ScopeType>('global');

  const [enableQuotaSpend, setEnableQuotaSpend] = useState(false);

  const [isDraft, setIsDraft] = useState(true);
  const [enablePolling, setEnablePolling] = useState(false);
  // For display in the publishing modal
  const [publishingModels, setPublishingModels] = useState<
    LibraryModelVersion[]
  >([]);
  // To track test case creation status
  const [publishedPromptModels, setPublishedPromptModels] = useState<
    LibraryModelVersion[]
  >([]);

  const filters = useSelector(slices.xRayLibrary.selectors.selectFilters);

  const modelsInCart = useSelector(slices.xRayLibrary.selectors.selectCart);

  const categoryModels = useSelector(
    slices.xRayLibrary.selectors.selectCategoryModels,
  );

  const { consumedQuota } = useGetModelsSummary();

  const modelText = pluralize(modelsInCart.length, 'model', {
    enableCount: false,
  });
  const modelCountText = `${modelsInCart.length} ${modelText}`;
  const saveDraftText = `Save as ${pluralize(modelsInCart.length, 'Draft', {
    enableCount: false,
  })}`;

  const [
    publishLibraryModels,
    { isLoading: isPublishing },
  ] = api.endpoints.publishLibraryModels.useMutation();

  const [
    createTestCase,
  ] = api.endpoints.createPromptModelTestCase.useMutation();

  const {
    data: publishingModelsData = [],
  } = api.endpoints.getLibraryModelsPublishStatus.useQuery(
    {},
    { pollingInterval: DEFAULT_POLL_INTERVAL_MS, skip: !enablePolling },
  );

  const isCreatingTestCases = Boolean(
    publishedPromptModels.length &&
      publishedPromptModels.some((model) => model.testCaseState === 'pending'),
  );

  const disablePublish = isPublishing || enablePolling || isCreatingTestCases;
  const publishingTooltip = disablePublish
    ? `${capitalize(modelText)} ${
        modelsInCart.length === 1 ? 'is' : 'are'
      } still ${isDraft ? 'saving' : 'publishing'}…`
    : undefined;

  const scopes = useMemo(() => {
    return scopeType === 'global'
      ? updateAllModelsGlobalScope({
          globalFilters: filters.globalFilters,
          modelsInCart,
        })
      : updateAllModelsCustomScope({
          categoryFilters: filters.categoryFilters,
          categoryModels,
        });
  }, [scopeType, filters]);

  const [confirmPublish, showConfirmPublish, hideConfirmPublish] = useModal({
    title: 'Are you sure you want to publish?',
    children: (
      <Layout id={PUBLISH_ELEMENT_ID} preset="sections">
        <Section title="Publish model">
          <Text>
            This will consume {modelsInCart.length} model quota permanently. Are
            you sure you want to publish?
          </Text>
        </Section>
        <Checkbox
          name="confirm-quota"
          option={{
            label: `Yes, I understand this will use ${modelsInCart.length} model quota when I publish.`,
            value: 'confirm-quota',
          }}
          onChange={() => {
            setEnableQuotaSpend(!enableQuotaSpend);
          }}
          value={enableQuotaSpend}
        />
      </Layout>
    ),
    primaryAction: {
      disabled: !enableQuotaSpend,
      tooltip: !enableQuotaSpend
        ? 'Please confirm that you understand the quota spend.'
        : undefined,
      isLoading: isPublishing,
      text: isPublishing ? 'Publishing…' : 'Publish',
      onClick: () => handlePublish(),
    },
  });

  const [confirmDraft, showConfirmDraft, hideConfirmDraft] = useModal({
    title: saveDraftText,
    children: (
      <Text>
        {modelCountText} will be saved as{' '}
        {modelsInCart.length === 1 ? 'a draft' : 'drafts'}. You can make
        adjustments and publish {modelsInCart.length === 1 ? 'it' : 'them'}{' '}
        later in X-Ray.
      </Text>
    ),
    primaryAction: {
      isLoading: isPublishing,
      text: isPublishing ? 'Saving…' : saveDraftText,
      onClick: () => handlePublish(),
    },
  });

  const allModelsFailed = publishingModels.every(
    (model) => model.publishState === 'error',
  );

  const handleNavigate = () => {
    dispatch(slices.xRayLibrary.actions.clearCart());
    if (publishingModels.length === 1 && publishingModels[0].promptModelId) {
      navigate(
        `${RoutePathType.AutomationHubFields}/${publishingModels[0].promptModelId}`,
      );
    } else {
      navigate(RoutePathType.AutomationHubFields);
    }
  };

  const [publishingModal, showPublishingModal] = useModal({
    children: (
      <ModelPublishStates
        isDraft={isDraft}
        models={publishingModels.map((model) => ({
          ...model,
          testCaseState: publishedPromptModels.find(
            (pm) => pm.promptModelId === model.promptModelId,
          )?.testCaseState,
        }))}
      />
    ),
    disableHideOnEscape: true,
    primaryAction: allModelsFailed
      ? undefined
      : {
          disabled: disablePublish,
          tooltip: publishingTooltip,
          text: `View ${modelText}`,
          onClick: handleNavigate,
        },
    title: allModelsFailed
      ? `${pluralize(modelsInCart.length, 'Error', {
          enableCount: false,
        })} ${isDraft ? 'saving' : 'publishing'} ${modelText}`
      : publishingModels.every((model) => model.publishState !== 'pending') &&
        !isCreatingTestCases
      ? `${capitalize(modelText)} ${isDraft ? 'saved' : 'published'}`
      : `${isDraft ? 'Saving' : 'Publishing'} ${modelText}…`,
    onHide: () => {
      dispatch(slices.xRayLibrary.actions.clearCart());
      onBack();
    },
  });

  const handlePublish = () => {
    publishLibraryModels({
      models: modelsInCart,
      scopes,
      isDraft,
    })
      .unwrap()
      .then((publishModels) => {
        if (publishModels?.length) {
          setEnablePolling(true);
          setPublishingModels(publishModels);
          isDraft ? hideConfirmDraft() : hideConfirmPublish();
          showPublishingModal();
        }
      })
      .catch(() => {
        toast({
          message: `There was an error ${
            isDraft ? 'saving' : 'publishing'
          } the ${modelText}. Please try again.`,
          status: 'danger',
        });
      });
  };

  useEffect(() => {
    if (publishingModelsData.length) {
      const models = publishingModelsData.map((modelPublish) => {
        const model = modelsInCart.find(
          (model) => model.libraryModelId === modelPublish.libraryModelId,
        );
        return {
          ...model,
          ...modelPublish,
        } as LibraryModelVersion;
      });
      setPublishingModels(models);

      // Mark the newly published models for test case creation
      const newlyPublishedModels = models
        .filter(
          (model) =>
            model.publishState === 'done' &&
            !publishedPromptModels.find(
              (pm) => pm.promptModelId === model.promptModelId,
            ),
        )
        .map(
          (model) =>
            ({
              ...model,
              testCaseState: 'pending',
            } as LibraryModelVersion),
        );

      if (newlyPublishedModels.length) {
        setPublishedPromptModels((prev) => [...newlyPublishedModels, ...prev]);
        newlyPublishedModels.forEach((model) => {
          if (model.promptModelId) {
            createTestCase({ modelId: model.promptModelId, version: 1 })
              .unwrap()
              .then(
                // on success
                (testCase) => {
                  if (testCase) {
                    setPublishedPromptModels((prev) => {
                      const updatedPromptModelInfos = prev.map((prevInfo) => {
                        if (prevInfo.promptModelId === model.promptModelId) {
                          return {
                            ...prevInfo,
                            testCaseState: 'done' as const,
                          };
                        }
                        return prevInfo;
                      });
                      return updatedPromptModelInfos;
                    });
                  }
                },
                // on error
                () => {
                  setPublishedPromptModels((prev) => {
                    const updatedPromptModelInfos = prev.map((prevInfo) => {
                      if (prevInfo.promptModelId === model.promptModelId) {
                        return { ...prevInfo, testCaseState: 'error' as const };
                      }
                      return prevInfo;
                    });
                    return updatedPromptModelInfos;
                  });
                },
              );
          }
        });
      }

      // If all models have finished publishing, stop polling
      if (
        publishingModelsData.every((model) => model.publishState !== 'pending')
      ) {
        setEnablePolling(false);
      }
    }
  }, [publishingModelsData]);

  return (
    <Modal
      disableHideOnEscape
      isFullPage
      isVisible
      backAction={{ text: 'Back', onClick: onBack }}
      cancelText="Close"
      headerCalloutContent={
        <Layout spacing={4} align="flex-start">
          <Layout direction="column">
            <Text color="text.quiet">Models</Text>
            <Text>{consumedQuota} used</Text>
          </Layout>
          <Layout direction="column">
            <Text color="text.quiet">Added Models</Text>
            <Text color="action.link">{modelCountText}</Text>
          </Layout>
        </Layout>
      }
      primaryAction={{
        disabled: disablePublish,
        tooltip: publishingTooltip,
        text: 'Review & Publish',
        onClick: () => {
          setIsDraft(false);
          showConfirmPublish();
        },
      }}
      secondaryActions={[
        {
          disabled: disablePublish,
          tooltip: publishingTooltip,
          text: saveDraftText,
          onClick: () => {
            setIsDraft(true);
            showConfirmDraft();
          },
        },
      ]}
      title="2. Add Scope"
      onCancel={onCancel}
    >
      <Section
        title="Set scope for all categories"
        description="Choose between setting a global scope for all categories or setting
        separate scopes for each category."
      >
        <Layout preset="sections">
          <CardSelect
            direction="row"
            name="scope"
            isMulti={false}
            options={[
              {
                label: 'Set Global Scope',
                description: `Apply filters to all categories to choose which documents the ${modelText} will run on.`,
                value: 'global',
                isActive: scopeType === 'global',
                width: '50%',
              },
              {
                label: 'Set Custom Scope',
                description: 'Define scope for each category.',
                value: 'custom',
                isActive: scopeType === 'custom',
                width: '50%',
              },
            ]}
            onChange={(value) => setScopeType(value as ScopeType)}
            value={scopeType}
          />
          <Divider />
          {scopeType === 'global' && <GlobalScope />}
          {scopeType === 'custom' && <CategoryScope />}
        </Layout>
      </Section>
      <ModelPreview hideActions />
      {confirmDraft}
      {confirmPublish}
      {publishingModal}
    </Modal>
  );
};
