import { Layout, LoadingShimmer, Text } from '~/eds';

import { useGetModelsSummary } from './billing/hooks';

export const ModelStatusSummary = () => {
  const {
    isLoading,
    total,
    published,
    draft,
    suspended,
    deleted,
  } = useGetModelsSummary();

  const statusLabels = ['all', 'published', 'draft', 'suspended', 'deleted'];

  const getModelProps = (status: string) => {
    switch (status) {
      case 'published':
        return {
          label: 'Total Published',
          count: published,
        };
      case 'suspended':
        return {
          label: 'Total Suspended',
          count: suspended,
        };
      case 'deleted':
        return {
          label: 'Total Deleted',
          count: deleted,
        };
      case 'draft':
        return {
          label: 'Total Drafts',
          count: draft,
        };
      case 'all':
      default:
        return {
          label: 'Total Models',
          count: total,
        };
    }
  };

  const statusItems = statusLabels.map((status, index) => {
    const { label, count } = getModelProps(status);

    return (
      <Layout key={index} direction="column" align="flex-start">
        <Text variant="tiny" color="text.secondary">
          {label}
        </Text>
        {isLoading ? (
          <LoadingShimmer width="50" height="filter.height" />
        ) : (
          <Text>{count}</Text>
        )}
      </Layout>
    );
  });

  return (
    <Layout direction="column" spacing={2}>
      <Text variant="body-medium">Model Summary:</Text>
      <Layout spacing={4}>{statusItems}</Layout>
    </Layout>
  );
};
