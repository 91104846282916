import { createSlice } from '@reduxjs/toolkit';
import { get, isEqual, pick } from 'lodash';

import { toFilter } from '~/components/SearchV2/SearchV2.utils';
import { SEARCH_COUNT_SINGLE_VALUE_ID } from '~/components/Shared/Charts/constants';
import { types } from '~/eds';
import { SliceType } from '~/enums';
import { FieldId, Filter } from '~/evifields';
import { SearchDocumentItem } from '~/features/advanced-search';
import { Dashboard, SearchItem } from '~/redux/api/methods';
import { toSearchV3Filters } from '~/redux/api/methods/searchV3';
import {
  Chart,
  ChartType,
  ColumnSortOrder,
  DropdownFieldValue,
  Nullable,
  SearchFilter,
  TableSettings,
} from '~/types';
import { getValidEntity } from '~/utils/object';
import { DEFAULT_TABLE_SORT_COLUMN_ORDER } from '~/utils/table';

import { getPersistedState, ReduxPath } from '../persist';

type ColumnWidths = {
  [key: string]: number;
};

const name = SliceType.DashboardV2;

const keysToPersist: (keyof DashboardV2Slice)[] = ['columnWidths'];

type KeyMapToPersist = Partial<Record<keyof DashboardV2Slice, ReduxPath>>;
const keyMapToPersist: KeyMapToPersist = keysToPersist.reduce<KeyMapToPersist>(
  (prev, curr) => {
    prev[curr] = [name, curr];
    return prev;
  },
  {},
);
export const pathsToPersist = Object.values(keyMapToPersist);

const persistedState = {
  columnWidths: getPersistedState(keyMapToPersist.columnWidths!, {}),
};

export type DashboardV2Slice = {
  activeDashboard: Dashboard;
  charts: Chart[];
  columnOrder: string[];
  columnWidths: ColumnWidths;
  currentChart: Nullable<string>;
  dashboardDirty: boolean;
  fieldValues: {
    [fieldId: string]: DropdownFieldValue[];
  };
  intervals: Record<string, string>;
  chartWidths: Record<string, string>;
  page: number;
  pageSize: number;
  searchText: string;
  queryBuilder: any;
  queryBuilderPanelVisible: boolean;
  selectedFilters: Array<Filter>;
  crossFilters: Array<Filter>;
  sortBy: ColumnSortOrder;
  searchResultCache: Record<types.PilotId, SearchDocumentItem>;
};

interface InitProps {
  searchFilters: Record<FieldId, SearchFilter>;
  dashboard: Dashboard;
  tableSettings: TableSettings;
  defaultSortBy?: ColumnSortOrder;
}

export const initialState: DashboardV2Slice = {
  charts: [],
  columnOrder: [],
  currentChart: null,
  activeDashboard: {
    charts: [],
    columns: [],
    filters: [],
    id: '',
    name: '',
    query: [],
    is_default: false,
    creatorId: 0,
  },
  dashboardDirty: false,
  fieldValues: {},
  searchResultCache: {},
  intervals: {},
  chartWidths: {},
  page: 1,
  pageSize: 20,
  searchText: '',
  queryBuilder: undefined,
  queryBuilderPanelVisible: false,
  sortBy: DEFAULT_TABLE_SORT_COLUMN_ORDER,
  selectedFilters: [],
  crossFilters: [],
  ...persistedState,
};

export const getNonFilterCharts = (
  dashboard: Dashboard,
): Record<string, Chart> => ({
  [SEARCH_COUNT_SINGLE_VALUE_ID]: {
    id: SEARCH_COUNT_SINGLE_VALUE_ID,
    label: dashboard.name,
    section: 'General Information',
    type: 'single_value',
  },
});

export default createSlice({
  name,
  initialState,
  reducers: {
    deleteChart: (state, action) => {
      const index = state.charts.findIndex(
        (chart) => chart.id === action.payload.id,
      );
      state.charts.splice(index, 1);
      state.dashboardDirty = true;
    },
    initDashboard: (state, action: { payload: InitProps }) => {
      const {
        dashboard,
        searchFilters,
        tableSettings,
        defaultSortBy,
      } = action.payload;

      const sortBy = defaultSortBy ?? DEFAULT_TABLE_SORT_COLUMN_ORDER;

      const { charts, columns = [], filters, query } = dashboard;

      const isComplexQuery = !!query.find((q) => q.type === 'section');

      // Mixing the non filter chats and the chart filters to create a record of all charts
      const allChartsRecord: Record<string, Chart> = {
        ...getNonFilterCharts(dashboard),
        ...Object.fromEntries(
          Object.entries(searchFilters).map(([key, value]) => [
            key,
            convertFilterToChart(value),
          ]),
        ),
      };

      // Mapping the charts record into the charts array repecting the charts sort order.
      const chartFilters: Chart[] = getValidEntity(
        allChartsRecord,
        charts.map((chart) => chart.filter_id),
      );

      // Mapping the saved type
      state.charts = chartFilters.map((chart, index) => ({
        ...chart,
        type: charts[index].type ?? chart.type,
      }));

      charts
        .filter((chart) => chart.interval)
        .forEach((chart) => {
          state.intervals[chart.filter_id] = chart.interval!;
        });

      charts
        .filter((chart) => chart.width)
        .forEach((chart) => {
          state.chartWidths[chart.filter_id] = chart.width!;
        });

      const defaultFilters: Array<Filter> = [];
      filters.forEach((defaultFilter) => {
        const searchFilter = Object.values(searchFilters).find(
          (searchFilter) => searchFilter.id.toString() === defaultFilter,
        );
        if (searchFilter) {
          defaultFilters.push(toFilter(searchFilter.id));
        }
      });
      const providedFiltersAndValues = isComplexQuery
        ? []
        : toSearchV3Filters(query, searchFilters);

      const isDifferentDashboard = state.activeDashboard?.id !== dashboard?.id;

      state.activeDashboard = dashboard;
      state.selectedFilters =
        state.selectedFilters.length && !isDifferentDashboard
          ? state.selectedFilters
          : [...providedFiltersAndValues, ...defaultFilters];
      state.crossFilters = [];
      state.queryBuilder = isComplexQuery ? query : null;
      state.page = 1;
      state.dashboardDirty = false;
      state.columnOrder = columns;
      state.searchText = '';
      if (dashboard.is_default) {
        state.pageSize = 20;
        state.sortBy = sortBy;
      } else {
        state.columnWidths = tableSettings.columnWidths;
        state.pageSize = tableSettings.pageSize;
        state.sortBy = tableSettings.sortBy;
        if (!isEqual(tableSettings.columnOrder, dashboard.columns)) {
          state.dashboardDirty = true;
        }
      }
    },
    patchColumnWidths: (state, action: { payload: ColumnWidths }) => {
      state.columnWidths = {
        ...state.columnWidths,
        ...action.payload,
      };
    },
    setColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },
    setCurrentChart: (state, action) => {
      state.currentChart = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setChartInterval: (state, { payload }) => {
      state.intervals[payload.id] = payload.value;
      state.dashboardDirty = true;
    },
    setChartWidth: (state, { payload }) => {
      state.chartWidths[payload.id] = payload.value;
      state.dashboardDirty = true;
    },
    setChartType: (state, { payload }) => {
      state.charts = state.charts.map((chart) => {
        if (chart.id === payload.id) {
          return {
            ...chart,
            type: payload.value,
          };
        }
        return chart;
      });
      state.dashboardDirty = true;
    },
    setChartFilters: (state, action) => {
      state.charts = action.payload;
    },
    setDashboardDirty: (state, action) => {
      state.dashboardDirty = action.payload;
    },
    setFieldValues: (
      state,
      {
        payload,
      }: { payload: { fieldId: string; values: DropdownFieldValue[] } },
    ) => {
      state.fieldValues[payload.fieldId] = payload.values;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSearchText: (state, { payload }) => {
      state.searchText = payload;
    },
    setQueryBuilder: (state, action) => {
      state.queryBuilder = action.payload;
    },
    setQueryBuilderPanelVisible: (state, action) => {
      state.queryBuilderPanelVisible = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setCrossFilters: (state, action) => {
      state.crossFilters = action.payload;
    },
    setSortBy: (state, action) => {
      const newSortBy = { ...action.payload };
      state.sortBy = newSortBy;
    },
    patchSearchResultsCache: (
      state,
      { payload = [] }: { payload: SearchDocumentItem[] },
    ) => {
      payload.forEach((item) => (state.searchResultCache[item.id] = item));
    },
    patchEvisearchResultsCache: (
      state,
      { payload = [] }: { payload: SearchItem[] },
    ) => {
      payload.forEach(
        (item) =>
          (state.searchResultCache[item.id] = {
            ...item,
            document_handlers: get(
              item,
              'selected_field_values.document_handler_id_Document.value_structured',
              [],
            ),
            // TODO: Map types correctly once the API gets formalized
            permissions: item.permissions as any,
            selected_field_values: item.selected_field_values as any,
            id: item.id as any,
          }),
      );
    },

    reset: (state) => {
      return {
        ...initialState,
        ...pick(state, ['searchResultCache']),
        filters: state.selectedFilters,
      };
    },
  },
});

export function convertFilterTypeTochartType(filter: SearchFilter): ChartType {
  switch (filter.type) {
    case 'boolean':
    case 'enum':
      return 'donut';
    case 'age':
    case 'date':
    case 'number':
      return 'vertical_bar';
    default:
      return 'horizontal_bar';
  }
}

export const convertFilterToChart = (searchFilter: SearchFilter) => ({
  id: searchFilter.id,
  label: searchFilter.label,
  section: searchFilter.section,
  type: convertFilterTypeTochartType(searchFilter),
  filterType: searchFilter.type,
});
