import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  DeleteDashboard,
  RenameDashboard,
  ShareDashboard,
} from '~/components/SavedDashboards';
import { trackSegment } from '~/components/SegmentAnalytics';
import { showToast } from '~/components/Shared/EcToast';
import { PersistedTable } from '~/components/Shared/PersistedTable';
import { TableToggleFilter } from '~/components/Shared/TableToggleFilter';
import type { FilterInstance } from '~/components/Shared/TableToggleFilter';
import { User } from '~/components/Shared/User';
import { EmptyPage, formatDate, Layout, Link, PageLayout } from '~/eds';
import {
  SavedDashboardsActions,
  TableContextType,
  UserRoleType,
} from '~/enums';
import { useCurrentUser, useTableSettings } from '~/hooks';
import { api } from '~/redux';
import { DashboardTypesQueryParam, SavedDashboard } from '~/redux/api/methods';
import dashboardV2 from '~/redux/slices/dashboardV2';
import savedDashboards from '~/redux/slices/savedDashboards';
import { ERROR } from '~/types/toast.types';
import { DEFAULT_TABLE_SORT_COLUMN_ORDER } from '~/utils/table';

const filterOptions: FilterInstance<DashboardTypesQueryParam>[] = [
  { text: 'My Saved Dashboards', value: 'mine' },
  { text: 'Shared with me', value: 'shared' },
];

const Page = memo(() => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(DEFAULT_TABLE_SORT_COLUMN_ORDER);
  const [typesParam, setTypesParam] = useState<DashboardTypesQueryParam[]>([]);
  const user = useCurrentUser();
  const { tableSettings } = useTableSettings(TableContextType.SavedDashboards);
  useEffect(() => {
    if (tableSettings) {
      setPageSize(tableSettings.pageSize);
      setSortBy(tableSettings.sortBy);
    }
  }, [tableSettings]);

  const handleUpdate = (state: any, action?: any) => {
    if (action?.type === 'toggleSortBy') {
      const updatedSortBy = state.sortBy[0];

      setSortBy(updatedSortBy);
    }
  };

  const isCreatorOrAdmin = (dashboard: SavedDashboard) =>
    dashboard.creator_id === user.id || user.role === UserRoleType.Admin;

  const { data, isFetching, isError } = api.endpoints.getDashboardsV2.useQuery({
    page,
    pageSize,
    sortBy,
    types: typesParam,
  });

  const columns = useMemo(
    () => [
      {
        key: 'name',
        title: 'Name',
        renderCell: (dashboard: SavedDashboard) => {
          return (
            <Link pathname={`/dashboards/${dashboard.id}`}>
              {dashboard.name}
            </Link>
          );
        },
      },
      {
        key: 'created_by',
        title: 'Created by',
        disableSortBy: true,
        renderCell: (dashboard: SavedDashboard) => {
          return <User mode="avatar-name" id={dashboard.creator_id} />;
        },
      },
      {
        key: 'created_date',
        title: 'Created on date',
        mapCellProps: (savedDashboard: SavedDashboard) => ({
          text: formatDate(
            savedDashboard.created_date
              ? new Date(savedDashboard.created_date)
              : null,
          ),
        }),
      },
      {
        key: 'modified_date',
        title: 'Last Modified',
        mapCellProps: (savedDashboard: SavedDashboard) => ({
          text: formatDate(
            savedDashboard.modified_date
              ? new Date(savedDashboard.modified_date)
              : null,
          ),
        }),
      },
    ],
    [],
  );
  const rowActions = useMemo(() => {
    const defaultMenu = [
      {
        label: 'Rename',
        onClick: async (dashboard: SavedDashboard) => {
          trackSegment('selectRenameDashboard', {
            id: dashboard.id,
            name: dashboard.name,
            user_id: user.id,
            client_id: user.client,
          });
          dispatch(
            savedDashboards.actions.openModal({
              dashboard,
              name: SavedDashboardsActions.Rename,
            }),
          );
        },
        icon: '',
      },
      {
        label: 'Share',
        onClick: async (dashboard: SavedDashboard) => {
          trackSegment('selectShareDashboard', {
            id: dashboard.id,
            name: dashboard.name,
            user_id: user.id,
            client_id: user.client,
          });
          dispatch(
            savedDashboards.actions.openModal({
              dashboard,
              name: SavedDashboardsActions.Share,
            }),
          );
        },
        icon: '',
      },
      {
        label: 'Delete',
        onClick: async (dashboard: SavedDashboard) => {
          trackSegment('selectDeleteDashboard', {
            id: dashboard.id,
            name: dashboard.name,
            user_id: user.id,
            client_id: user.client,
          });
          dispatch(
            savedDashboards.actions.openModal({
              dashboard,
              name: SavedDashboardsActions.Delete,
            }),
          );
        },
        icon: '',
      },
    ];

    return defaultMenu;
  }, []);

  const onPaginate = useCallback(
    ({ pageIndex }: { pageIndex: number }) => {
      if (pageIndex !== page) {
        setPage(pageIndex);
      }
    },
    [page],
  );

  const onPageSizeChange = useCallback((pageSize: number) => {
    setPageSize(pageSize);
  }, []);

  useEffect(() => {
    if (isError) {
      showToast(ERROR, 'An error occurred while fetching saved dashboards.');
    }
  }, [isError]);

  useEffect(() => {
    dispatch(dashboardV2.actions.reset());
  }, []);

  const noResults = !isError && data?.results.length === 0;

  return (
    <PageLayout
      title="Saved Dashboards"
      loadingContent={{
        isLoading: isFetching,
        message: 'Loading saved dashboards…',
      }}
    >
      <TableToggleFilter
        options={filterOptions}
        selectedOptions={typesParam}
        onChange={setTypesParam}
      />
      {noResults ? (
        <EmptyPage preset="no-saved-dashboards" />
      ) : (
        <Layout direction="column" spacing={8} mt={6}>
          <PersistedTable
            context={TableContextType.SavedDashboards}
            name="Saved Dashboards"
            data={data?.results || []}
            totalCount={data?.total}
            columns={columns}
            getRowActions={(dashboard: SavedDashboard) => {
              return rowActions.map((rowAction) => {
                const disabled = !isCreatorOrAdmin(dashboard);
                return { ...rowAction, disabled };
              });
            }}
            options={{
              enableExportXlsx: false,
              enablePageSizeSelect: true,
              enableSelectRows: false,
            }}
            state={{
              pageIndex: page,
              pageSize,
              sortBy: [sortBy],
            }}
            onUpdate={handleUpdate}
            onPaginate={onPaginate}
            onPageSizeChange={onPageSizeChange}
            reactTableOptions={{
              autoResetSortBy: false,
              disableSortRemove: true,
              manualSortBy: true,
            }}
          />
        </Layout>
      )}

      <RenameDashboard />
      <ShareDashboard />
      <DeleteDashboard isLast={data?.total === 1} />
    </PageLayout>
  );
});

export default Page;
