import { api } from '~/redux';

export const useGetModelsSummary = () => {
  const {
    data: modelSummary = {
      total: 0,
      published: 0,
      draft: 0,
      suspended: 0,
      deleted: 0,
      consumed_quota: 0,
      total_quota: 0,
    },
    isLoading: isLoadingSummary,
  } = api.endpoints.getModelSummary.useQuery(undefined);

  return {
    consumedQuota: modelSummary.consumed_quota,
    totalQuota: modelSummary.total_quota,
    isLoading: isLoadingSummary,
    published: modelSummary.published,
    draft: modelSummary.draft,
    suspended: modelSummary.suspended,
    deleted: modelSummary.deleted,
    total: modelSummary.total,
  };
};
