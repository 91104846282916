import React, { memo, useMemo } from 'react';
import { useStyles } from 'uinix-ui';

import { SharedProgressProps } from '../../types';
import { formatNumber } from '../../utils';
import { Box } from '../Box';
import { Layout } from '../Layout';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { Donut } from './Donut';

interface Props extends SharedProgressProps {
  mode?: 'bar' | 'donut';
}

export const Progress = memo(
  ({
    isLoading,
    max,
    mode = 'bar',
    name,
    status = 'active',
    statsLabel,
    tooltip,
    value,
  }: Props) => {
    const styles = useStyles();

    const progressStyle = useMemo(
      () => ({
        backgroundColor: 'background.quiet',
        ...(isLoading ? styles.shimmer({}) : {}),
        height: 'progress.height',
        width: '100%',
        '::-moz-progress-bar': {
          backgroundColor: `status.${status}`,
        },
        '::-webkit-progress-bar': {
          backgroundColor: 'transparent',
        },
        '::-webkit-progress-value': {
          backgroundColor: `status.${status}`,
        },
      }),
      [isLoading, status],
    );

    let progress;
    switch (mode) {
      case 'donut': {
        progress = (
          <Donut
            max={max}
            name={name}
            status={status}
            tooltip={tooltip}
            value={value}
          />
        );
        break;
      }
      case 'bar':
      default: {
        progress = (
          <Tooltip tooltip={tooltip} width="100%">
            <Box
              as="progress"
              id={name}
              max={max}
              styles={progressStyle}
              value={value}
            />
          </Tooltip>
        );
        break;
      }
    }

    if (statsLabel) {
      const {
        formatLabel = defaultFormatLabel,
        position = 'left',
        textAlign,
        variant,
        width,
        color,
      } = statsLabel;
      const label = (
        <Text
          aria-labelledby={name}
          flex="none"
          textAlign={textAlign}
          variant={variant}
          w={width}
          color={color}
        >
          {formatLabel(value, max)}
        </Text>
      );

      switch (position) {
        case 'left':
          return (
            <Layout align="center" flex="auto" spacing={2}>
              {label} {progress}
            </Layout>
          );
        case 'top':
          return (
            <Layout direction="column" flex="auto" spacing={2}>
              {label}
              {progress}
            </Layout>
          );
        case 'bottom':
        default:
          return (
            <Layout direction="column" flex="auto" spacing={2}>
              {progress}
              {label}
            </Layout>
          );
      }
    }

    return progress;
  },
);

const defaultFormatLabel = (value: number, max: number) =>
  `${formatNumber(value)}/${formatNumber(max)}`;
