import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '~/eds';
import { Filter } from '~/evifields';
import { Filters } from '~/features/advanced-search/filters';
import { attachFilterType } from '~/features/filters/utils';
import { api, slices } from '~/redux';

import { useXraySearchFilters } from './hooks';

export const GlobalScope = () => {
  const dispatch = useDispatch();
  const filters = useSelector(slices.xRayLibrary.selectors.selectFilters);

  const {
    data: searchFilters,
    isFetching: isFetchingFilters,
  } = api.endpoints.getFilters.useQuery(undefined);

  const globalFilters = filters.globalFilters;

  const {
    parsedDefaultFilters,
    searchFilters: searchFiltersList,
  } = useXraySearchFilters({
    searchFilters,
  });

  useEffect(() => {
    if (globalFilters.length === 0 && searchFilters) {
      const filtersWithTypes = attachFilterType(
        parsedDefaultFilters,
        searchFilters,
      );

      dispatch(
        slices.xRayLibrary.actions.setFilters({
          ...filters,
          globalFilters: filtersWithTypes ?? [],
        }),
      );
    }
  }, [globalFilters, searchFilters]);

  return (
    <>
      <Text variant="section">Global Scope</Text>
      <Filters
        isLoading={isFetchingFilters}
        searchFilters={searchFiltersList}
        selectedFilters={filters.globalFilters}
        onChange={(updatedFilters: Filter[]) => {
          const filtersWithTypes = attachFilterType(
            updatedFilters,
            searchFilters,
          );
          dispatch(
            slices.xRayLibrary.actions.setFilters({
              ...filters,
              globalFilters: filtersWithTypes,
            }),
          );
        }}
      />
    </>
  );
};
