import { testHasTextMatch } from '~/eds';
import { Filter } from '~/evifields';
import { api } from '~/redux';
import { mapScopeToFilters } from '~/redux/api/methods/sylvanus/transformers';
import { Nullable, Uuid } from '~/types';

import { LibraryModelVersion, Tag } from './types';

export const useGetLatestLibraryModelVersions = ({
  category,
  filters,
  search,
}: {
  category?: Nullable<Tag>;
  filters?: Filter[];
  search?: Nullable<string>;
}): { models: LibraryModelVersion[]; isLoading: boolean } => {
  const {
    data: searchFilters,
    isFetching: isFetchingFilters,
  } = api.endpoints.getFilters.useQuery(undefined);

  const {
    data: models = [],
    isLoading: isLoadingModels,
  } = api.endpoints.getLibraryModels.useQuery({
    page_size: 500,
  });

  const isLoading = isLoadingModels || isFetchingFilters;

  const latestModelVersions = models.flatMap((model) =>
    model.versions.filter((version) => version.latest),
  );

  const modelVersions = latestModelVersions
    .map((version) => ({
      ...version,
      scope:
        version.config.scope && searchFilters
          ? mapScopeToFilters(version.config.scope, searchFilters)
          : [],
    }))
    .filter(testModelVersionMatchesCategory(category));

  return {
    models: getFilteredAndSearchedModels(modelVersions, search, filters),
    isLoading,
  };
};

export const useGetLibraryModelVersions = ({
  libraryModelId,
}: {
  libraryModelId: Uuid;
}): { versions: LibraryModelVersion[]; isLoading: boolean } => {
  const {
    data: versions = [],
    isLoading,
  } = api.endpoints.getLibraryModel.useQuery({
    libraryModelId,
  });
  return { versions, isLoading };
};

const getFilteredAndSearchedModels = (
  models: LibraryModelVersion[],
  search?: Nullable<string>,
  filters?: Filter[],
) => {
  const searchedModels = search ? filterBySearch(models, search) : models;
  return filters ? filterByFilters(searchedModels, filters) : searchedModels;
};

const filterBySearch = (models: LibraryModelVersion[], search: string) =>
  models.filter(
    (version) =>
      testHasTextMatch(version.label, search) ||
      testHasTextMatch(version.description, search),
  );

const filterByFilters = (models: LibraryModelVersion[], filters: Filter[]) => {
  // Possibly extend this if more filters are added.
  const publishStatusFilter = filters.find(
    (filter) => filter.fieldId === 'modelState',
  );
  // no filter selected means show all results
  return publishStatusFilter && publishStatusFilter.values.length
    ? models.filter((version) =>
        publishStatusFilter.values.includes(version.promptModelPublishStatus),
      )
    : models;
};

const testModelVersionMatchesCategory = (category?: Nullable<Tag>) => (
  version: LibraryModelVersion,
) => {
  if (category) {
    return category.label === 'Uncategorized'
      ? !version.tags?.length
      : Boolean(version.tags?.find((tag) => tag.label === category.label));
  }
  return true;
};
