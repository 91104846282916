import { SimpleChart } from '~/components/Shared/Charts/SimpleChart';
import { Divider, Layout, Markdown, Text } from '~/eds';

import { LibraryModelVersion } from '../../types';
import { getEntityTypeLabel } from '../utils';

interface Props {
  model: LibraryModelVersion;
}

export const ModelSummary = ({ model }: Props) => {
  return (
    <Layout preset="sections">
      <Text>{model.description}</Text>
      <Layout spacing={1}>
        <Text>Field Type:</Text>
        <Text variant="body-medium">{getEntityTypeLabel(model)}</Text>
      </Layout>
      {(model.sampleOutputs?.text ||
        model.sampleOutputs?.dashboard?.buckets) && <Divider />}
      <Layout spacing={2} justify="space-between">
        {model.sampleOutputs?.text && (
          <Layout flex="2" direction="column">
            <Text variant="body-medium">Sample outputs:</Text>
            <Markdown text={model.sampleOutputs.text} />
          </Layout>
        )}
        {model.sampleOutputs?.dashboard?.buckets && (
          <Layout flex="3" direction="column">
            <Text variant="body-medium">Dashboard visual</Text>
            <SimpleChart
              buckets={model.sampleOutputs.dashboard.buckets}
              type={model.sampleOutputs.dashboard.type}
              width={400}
            />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};
