import { Filter } from '~/evifields';
import { Filters } from '~/features/advanced-search/filters';
import { attachFilterType, getDefaultFilters } from '~/features/filters/utils';
import { api } from '~/redux';
import { buildFilterQuery } from '~/redux/api/methods/searchV3';
import { SearchFilter } from '~/types';

import { SearchQuery } from '../types';
import { mapQueryToFilters } from './QureryFilters.utils';

interface Props {
  query: SearchQuery;
  defaultFilters?: string[];
  searchFilters: SearchFilter[];
  placeholder?: string;
  isLoading?: boolean;
  readOnly?: boolean;
  onQueryChange: (updatedQuery: SearchQuery) => void;
}

export const QueryFilters = ({
  defaultFilters,
  searchFilters,
  isLoading: isLoadingFilters,
  placeholder,
  query,
  readOnly = false,
  onQueryChange,
}: Props) => {
  const {
    data: searchFiltersResults,
    isFetching: isFetchingFilters,
  } = api.endpoints.getFilters.useQuery(undefined);

  const parsedDefaultFilters = getDefaultFilters(
    defaultFilters,
    searchFiltersResults,
  );

  const defaultFiltersWithTypes = attachFilterType(
    parsedDefaultFilters,
    searchFiltersResults,
  );

  const filters =
    query.length > 0 && searchFiltersResults
      ? mapQueryToFilters(query, searchFiltersResults)
      : defaultFiltersWithTypes;

  const handleChange = (updatedFilters: Filter[]) => {
    const withTypes = attachFilterType(updatedFilters, searchFiltersResults);

    const updatedQuery = buildFilterQuery({
      booleanQuery: '',
      filters: withTypes,
    });

    onQueryChange(updatedQuery);
  };

  const isLoading = isLoadingFilters || isFetchingFilters;

  return (
    <Filters
      isLoading={isLoading}
      onChange={handleChange}
      placeholder={placeholder}
      readOnly={readOnly}
      selectedFilters={filters}
      searchFilters={searchFilters} //Needs an searchFilters array
    />
  );
};
