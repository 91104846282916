export const integrateSegment = (user) => {
  if (typeof window.analytics != 'undefined' && user) {
    const userInfo = {
      role: user.role_uuid?.name ?? '',
      groupId: user.client,
      clientName: user.client_name,
      workflowAdmin: user.is_workflow_admin,
      roleType: user.role_uuid?.role_type ?? '',
      departments: (user.departments ?? []).join('; '),
      rolePermissions: (user.permission_names ?? []).join('; '),
    };
    window.analytics.identify(`${user.id}_${user.client}`, userInfo);
  }
};

export const trackSegment = (name, properties) => {
  if (typeof window.analytics != 'undefined') {
    window.analytics.track(name, properties);
  }
};

export const trackPage = () => {
  if (typeof window.analytics != 'undefined') {
    window.analytics.page();
  }
};
