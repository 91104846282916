import { isEqual } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'uuid';

import {
  Actions,
  ContentContainer,
  Grid,
  Layout,
  Modal,
  Panel,
  pluralize,
  SearchInput,
  Text,
  useToast,
  useToggle,
} from '~/eds';
import { Filter } from '~/evifields';
import { slices } from '~/redux';
import { RoutePathType, useRouting } from '~/routing';
import { Nullable } from '~/types';

import { useGetModelsSummary } from '../../billing/hooks';
import { useGetLatestLibraryModelVersions } from '../hooks';
import { Categories } from './Categories';
import { ModelCard } from './ModelCard';
import { ModelCart } from './ModelCart';
import { ModelLibraryFilters } from './ModelLibraryFilters';
import { ModelPreview } from './ModelPreview';
import { testIsModelPublished } from './utils';

type Callback = () => void;

interface Props {
  onCancel: Callback;
  onNext: Callback;
}

export const ModelLibrary = ({ onCancel, onNext }: Props) => {
  const dispatch = useDispatch();
  const { navigate } = useRouting();
  const { toast } = useToast();
  const [isCartVisible, showCart] = useToggle(false);

  const category = useSelector(slices.xRayLibrary.selectors.selectCategory);
  const [search, setSearch] = useState<Nullable<string>>(null);
  const initialFilters: Filter[] = [
    {
      id: uuid.v4(),
      fieldId: 'modelState',
      operatorId: 'contains_any',
      values: [],
    },
  ];
  const [filters, setFilters] = useState<Filter[]>(initialFilters);

  const modelsInCart = useSelector(slices.xRayLibrary.selectors.selectCart);

  const { consumedQuota } = useGetModelsSummary();

  const { models, isLoading } = useGetLatestLibraryModelVersions({
    category,
    filters,
    search,
  });

  const unpublishedModels = models.filter(
    (model) => !testIsModelPublished(model),
  );

  const testIsDirty = (a: Filter[], b: Filter[]) => {
    const aValues = a.map((f) => f.values).flat();
    const bValues = b.map((f) => f.values).flat();
    return !isEqual(aValues, bValues);
  };

  const loadingContent = { isLoading, message: 'Loading Model Library…' };
  const emptyContent =
    models.length === 0
      ? {
          message: `${
            search
              ? `No models match the search term "${search}" ${
                  category ? `in the category "${category.label}"` : ''
                }.`
              : `No models found. Try a different category${
                  filters ? ' or reset the filter' : ''
                }.`
          }`,
          action: testIsDirty(filters, initialFilters)
            ? {
                text: 'Reset Filter',
                onClick: () => setFilters(initialFilters),
              }
            : undefined,
        }
      : undefined;

  const cards = models.map((model) => (
    <ModelCard model={model} search={search ?? undefined} />
  ));

  const tooltip =
    modelsInCart.length === 0 ? 'Select at least one model.' : undefined;

  return (
    <Modal
      disableHideOnEscape
      isFullPage
      isVisible
      enableContentPadding={false}
      headerCalloutContent={
        <Layout spacing={4}>
          <Layout direction="column" align="flex-start">
            <Text color="text.quiet">Models</Text>
            <Text>{consumedQuota} used</Text>
          </Layout>
          <Actions
            actions={[
              {
                text: `Selected Models (${modelsInCart.length})`,
                level: 'tertiary',
                onClick: showCart,
              },
            ]}
          />
        </Layout>
      }
      loadingContent={loadingContent}
      title="1. Select from Model Library"
      primaryAction={{
        text: 'Next: Add Scope',
        onClick: onNext,
        disabled: Boolean(tooltip),
        tooltip,
      }}
      leftActions={[
        {
          icon: 'launch',
          iconPosition: 'left',
          text: 'New blank model',
          level: 'tertiary',
          onClick: () => navigate(`${RoutePathType.AutomationHubFields}/new`),
        },
      ]}
      panel={
        isCartVisible
          ? {
              children: <ModelCart />,
              width: '300px',
              enableContentPadding: false,
            }
          : undefined
      }
      cancelText="Close"
      onCancel={onCancel}
      onHide={onCancel}
    >
      <Layout direction="column" overflowY="hidden">
        <Layout preset="form-fields" px={4} py={6}>
          <SearchInput
            name="x-ray-model-library-search-input"
            placeholder="Search models"
            value={search}
            onChange={setSearch}
          />
          <ModelLibraryFilters filters={filters} onChange={setFilters} />
        </Layout>
        <Layout borderTop="border" h="calc(100vh - 260px)" overflowY="hidden">
          <Categories filters={filters} search={search} />
          <Panel
            actions={[
              {
                disabled: models.length === 0 || unpublishedModels.length === 0,
                tooltip:
                  models.length === 0
                    ? 'No models to add.'
                    : unpublishedModels.length === 0
                    ? `${
                        models.length === 1 ? 'Model has' : 'Models have'
                      } already been published.`
                    : undefined,
                level: 'tertiary',
                mode: 'link',
                text: 'Add All Models',
                onClick: () => {
                  if (!category) {
                    toast({ message: 'Select a category.', status: 'warning' });
                  } else {
                    dispatch(
                      slices.xRayLibrary.actions.addAllModelsToCart({
                        models: unpublishedModels,
                        category,
                      }),
                    );
                    toast({
                      message: `Added ${pluralize(
                        unpublishedModels.length,
                        'model',
                      )} to cart.`,
                      status: 'success',
                    });
                  }
                },
              },
            ]}
            title={`${category ? category.label : 'All Categories'} (${
              models.length
            })`}
            width="calc(100vw - 284px)"
          >
            <ContentContainer placeholderContent={emptyContent}>
              <Grid columns={3} columnSpacing={4} rowSpacing={4}>
                {cards}
              </Grid>
            </ContentContainer>
          </Panel>
        </Layout>
        <ModelPreview />
      </Layout>
    </Modal>
  );
};
