import { Filter } from '~/evifields';
import {
  buildFilterQuery,
  toSearchV3Filter,
} from '~/redux/api/methods/searchV3';
import { EntityQuery, FilterQueryEntity } from '~/types';

import { SearchFilterRecord, SearchQuery } from '../types';

export const OPERATORS_WITH_NO_VALUES = new Set([
  'is_blank',
  'is_not_blank',
  'is_true',
  'is_false',
]);

export const mapQueryToFilters = (
  query: SearchQuery,
  searchFilters: SearchFilterRecord,
): Filter[] => {
  return (query as EntityQuery[])
    .filter(
      (filter: EntityQuery): filter is FilterQueryEntity =>
        filter.type === 'filter',
    )
    .map((filter: FilterQueryEntity) =>
      toSearchV3Filter(filter, searchFilters),
    );
};

export const mapFiltersToQuery = (filters: Filter[]) => {
  const query: SearchQuery = buildFilterQuery({
    booleanQuery: '',
    filters,
  });

  return query;
};

export const isActiveQuery = (query: any) => {
  if (!query) {
    return [];
  } else {
    //@ts-ignore
    return query.reduce((acc, item, index, array) => {
      if (
        item.type === 'filter' &&
        item.operator &&
        (item.value?.value_list?.length > 0 ||
          OPERATORS_WITH_NO_VALUES.has(item.operator!))
      ) {
        // Include filter with values
        acc.push(item);

        // Include the next operator only if followed by another valid filter
        if (
          array[index + 1]?.type === 'operator' &&
          array[index + 2]?.type === 'filter' &&
          array[index + 2]?.operator &&
          array[index + 2]?.value?.value_list?.length > 0
        ) {
          acc.push(array[index + 1]);
        }
      }
      return acc;
    }, []);
  }
};
