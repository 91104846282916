import { useFieldsWithLazyLoad } from '~/features/filters';
import { getDefaultFilters } from '~/features/filters/utils';

import { INITIAL_FILTERS } from './constants';

export const useXraySearchFilters = ({
  searchFilters,
}: {
  searchFilters: any;
}) => {
  const lazySearchFilters = useFieldsWithLazyLoad({
    filters: Object.values(searchFilters ?? {}),
    fieldValues: {},
  });

  const parsedDefaultFilters = getDefaultFilters(
    INITIAL_FILTERS,
    searchFilters,
  );

  return {
    searchFilters: lazySearchFilters,
    parsedDefaultFilters,
  };
};
