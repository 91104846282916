import { memo } from 'react';

import { Box } from '../../Box';
import { Footer } from '../../Footer';
import { Card, Props as CardProps } from '../Card';

interface ActionCardProps extends CardProps {
  options?: {
    /** Optionally show the actions on hover only. Defaults to false (i.e. always show the actions). */
    enableHoverActions?: boolean;
    /** Optionally hide the footer border by setting this to false. Defaults to true. */
    enableFooterBorder?: boolean;
  };
}

export const ActionCard = memo(
  ({ children, footer, options, ...rest }: ActionCardProps) => {
    return (
      <Card {...rest}>
        <Box
          styles={componentStyles.base({
            enableHoverActions: options?.enableHoverActions,
          })}
        >
          {children}
          {footer && (
            <Footer
              isEmbedded
              enableBorder={options?.enableFooterBorder}
              size="s"
              {...footer}
            />
          )}
        </Box>
      </Card>
    );
  },
);

const componentStyles = {
  base: ({ enableHoverActions }: { enableHoverActions?: boolean }) => {
    return {
      '& footer [role="toolbar"]': {
        opacity: enableHoverActions ? 0 : 1,
        transition: 'opacity 0.1s ease-in-out',
      },
      '&:hover footer [role="toolbar"]': {
        opacity: 1,
      },
    };
  },
};
