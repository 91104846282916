import { Filter } from '~/evifields';
import { SearchQuery } from '~/features/advanced-search/types';
import { pilot } from '~/services';

import { buildFilterQuery } from '../searchV3';

interface Request {
  filters?: Filter[];
  query?: SearchQuery;
  clauseName: string;
  //Temporary flag
  flag?: boolean;
}

export const updateClause = async ({
  flag,
  query,
  filters,
  clauseName,
}: Request) => {
  const searchQuery = flag
    ? query
    : filters
    ? buildFilterQuery({
        booleanQuery: '',
        filters,
      })
    : [];

  return await pilot.patch(`/ai-command-center/update/?clause=${clauseName}`, {
    search_query: searchQuery,
  });
};
