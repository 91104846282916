import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Tabs, types, useToast } from '~/eds';
import { slices } from '~/redux';

import { getCategoryChips, getChip, testIsModelPublished } from '../utils';
import { ModelSummary } from './ModelSummary';
import { VersionHistory } from './VersionHistory';

type TabType = 'summary' | 'versions';

interface Props {
  hideActions?: boolean;
}

export const ModelPreview = ({ hideActions }: Props) => {
  const { toast } = useToast();
  const dispatch = useDispatch();
  const model = useSelector(slices.xRayLibrary.selectors.selectModelPreview);
  const category = useSelector(slices.xRayLibrary.selectors.selectCategory);
  const modelsInCart = useSelector(slices.xRayLibrary.selectors.selectCart);
  const isInCart = Boolean(
    modelsInCart.find(
      (cartModel) => cartModel.libraryModelId === model?.libraryModelId,
    ),
  );
  const isPublished = model && testIsModelPublished(model);
  const modelActions = useMemo(() => {
    if (hideActions) {
      return [];
    }
    const actions: types.UserAction[] = [];
    if (model && !isInCart && !isPublished && category) {
      actions.push({
        icon: 'plus-circle' as const,
        iconPosition: 'left' as const,
        text: 'Add Model',
        onClick: () => {
          dispatch(
            slices.xRayLibrary.actions.addModelToCart({
              model,
              category,
            }),
          );
          toast({ message: 'Added to cart.', status: 'success' });
        },
      });
    }
    if (model && isInCart) {
      actions.push({
        icon: 'trash' as const,
        iconPosition: 'left' as const,
        text: 'Remove',
        onClick: () => {
          dispatch(slices.xRayLibrary.actions.removeModelFromCart(model));
          toast({ message: 'Removed from cart.', status: 'success' });
        },
      });
    }
    return actions;
  }, [category, hideActions, isInCart, isPublished, model]);

  const [tab, setTab] = useState<TabType>('summary');

  if (!model) {
    return null;
  }

  const publishStatusChip = getChip(model.promptModelPublishStatus);
  const alsoInChips = getCategoryChips({ model, category });

  return (
    <Modal
      isVisible
      chips={publishStatusChip ? [publishStatusChip] : undefined}
      leftContent={alsoInChips}
      secondaryActions={modelActions}
      title={model.label}
      width="l"
      cancelText="Close"
      onCancel={() => {
        dispatch(slices.xRayLibrary.actions.setModelPreview(null));
        setTab('summary');
      }}
    >
      <Tabs<TabType>
        tabs={[
          {
            label: 'Model Summary',
            panel: <ModelSummary model={model} />,
            value: 'summary',
          },
          {
            label: 'Version History',
            panel: <VersionHistory libraryModelId={model.libraryModelId} />,
            value: 'versions',
          },
        ]}
        selectedTab={tab}
        onSelectTab={setTab}
      />
    </Modal>
  );
};
